<template>
  <div class="about-us">
    <div class="container">
      <section class="about-us__goals">
        <base-sub-heading class="about-us__header-sub-heading" align="center">
          <span v-html="$t('aboutUs.aboutUs.subTitle')" />
        </base-sub-heading>
        <base-heading class="about-us__header-heading" align="center">
          <span v-html="$t('aboutUs.aboutUs.title')" />
        </base-heading>
        <div class="about-us__description">
          <div class="about-us__content">
            <h4 class="about-us__content-header">{{ $t("aboutUs.aboutUs.content.header") }}</h4>
            <div class="about-us__text" v-html="$t('aboutUs.aboutUs.content.paragraphs')" />
            <base-button to="auth/sign-up" class="about-us__button">
              {{ $t("aboutUs.aboutUs.content.button") }}
            </base-button>
          </div>
          <div class="about-us__image-wrapper">
            <img class="about-us__image" src="assets/images/about.jpg" alt="" />
            <!-- <course-tile :course="course" class="about-us__course" disabled/> -->
          </div>
          <img class="about-us__dots" alt="" src="/assets/images/dots--big.svg" />
        </div>
      </section>
      <section class="about-us__mission">
        <base-sub-heading class="about-us__header-sub-heading" align="center">
          <span v-html="$t('aboutUs.mission.subTitle')" />
        </base-sub-heading>
        <base-heading class="about-us__header-heading" align="center">
          <span v-html="$t('aboutUs.mission.title')" />
        </base-heading>
        <div class="about-us__carousel-wrapper">
          <div class="about-us__controls">
            <base-button
              type="text"
              class="about-us__control"
              :class="currentSlide === 0 ? 'about-us__control--active' : ''"
              @click="currentSlide = 0"
              small
            >
              {{ $t("aboutUs.mission.beginner") }}
            </base-button>
            <base-button
              type="text"
              class="about-us__control"
              :class="currentSlide === 1 ? 'about-us__control--active' : ''"
              @click="currentSlide = 1"
              small
            >
              {{ $t("aboutUs.mission.author") }}
            </base-button>
            <base-button
              type="text"
              class="about-us__control"
              :class="currentSlide === 2 ? 'about-us__control--active' : ''"
              @click="currentSlide = 2"
              small
            >
              {{ $t("aboutUs.mission.company") }}
            </base-button>
          </div>
          <hooper
            :settings="settings"
            class="about-us__hooper"
            style="height: 550px"
            ref="carousel"
            @slide="currentSlide = $event.currentSlide"
          >
            <slide class="about-us__slide">
              <about-us-advantages type="beginner" />
            </slide>
            <slide class="about-us__slide">
              <about-us-advantages type="author" />
            </slide>
            <slide class="about-us__slide">
              <about-us-advantages type="company" />
            </slide>
          </hooper>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'AboutUs',
  metaInfo: {
    title: 'Edunails - About Us ',
    titleTemplate: null,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Learn about our history, years of experience in the nail art industry, our nail art awards & our mission.',
      },
    ],
  },
  components: {
    Hooper,
    Slide,
  },
  data: () => ({
    currentSlide: 0,
    settings: {
      vertical: false,
      wheelControl: false,
      itemsToShow: 1,
      keysControl: false,
      trimWhiteSpace: true,
      mouseDrag: false,
      centerMode: true,
      breakpoints: {
        769: {
          vertical: true,
        },
      },
    },
    course: {
      id: 1,
      name: 'Nauka zasad stylizacji paznokci',
      description: 'W tym kursie nauczysz się podstaw stylizacji paznokci u rąk. Zobaczysz wiele rzeczy!',
      image: 'https://i.pinimg.com/736x/94/7f/7a/947f7a144f96b410bae1b72490c23bcc.jpg',
      videoURL: '',
      author: 'Czesława Wąs',
      lvl: 1,
      likes: 512,
      price: 3650,
      currency: 'pl',
    },
  }),
  watch: {
    currentSlide() {
      this.$refs.carousel.slideTo(this.currentSlide);
    },
  },
  mounted() {
    this.$refs.carousel.update();
  },
};
</script>
<style lang="scss" scoped src="./AboutUs.scss" />
